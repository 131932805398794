import { cn } from '#app/utils/misc'

interface BodyProps extends React.HTMLAttributes<HTMLParagraphElement> {
	as?: 'btn-md' | 'btn-sm' | 'nav-md' | 'nav-sm'
}

const PlentyButtonsAndLinks = ({
	as = 'btn-sm',
	className,
	children,
	...rest
}: BodyProps) => {
	switch (as) {
		case 'btn-md':
			return (
				<p className={cn(`text-[16px] leading-[22px]`, className)} {...rest}>
					{children}
				</p>
			)
		case 'btn-sm':
			return (
				<p
					className={cn(`text-[14px] leading-[22px] md:text-[16px]`, className)}
					{...rest}
				>
					{children}
				</p>
			)
		case 'nav-md':
			return (
				<p
					className={cn(`text-[14px] leading-[22px] md:text-[16px]`, className)}
					{...rest}
				>
					{children}
				</p>
			)
		case 'nav-sm':
			return (
				<p className={cn(` text-[14px] leading-[22px]`, className)} {...rest}>
					{children}
				</p>
			)
	}
}

export default PlentyButtonsAndLinks
